import React from 'react';
import './App.css';
import CloakForm from './CloakForm';
import ShortForm from './ShortForm';

function App() {
  return (
    <div className="App">
      <div className="header-container">
        <div className="Header">
          <header className="App-header">
            <h1>Links App</h1>
          </header>
        </div>
        <div className="ShortLinks">
          <header className="App-header">
            <h1>Short Links</h1>
          </header>
          <ShortForm />
        </div>
        <div className="CloakLinks">
          <header className="App-header">
            <h1>Cloak Links</h1>
          </header>
          <CloakForm />
        </div>
      </div>
    </div>
  );
}

export default App;
