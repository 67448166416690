import React from "react";
import { Formik, Form, Field } from 'formik';

const ShortForm = () => {
    const [shortLink, setShortLink] = React.useState('');

    return (
        <>
            <Formik
                initialValues={{ longUrl: '', shortUrl: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    const requestOptions: RequestInit = {
                        method: 'POST',
                        credentials: "include",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(values)
                    };
                    fetch('https://app.bfac.link/api/v1/links/shorts', requestOptions)
                        .then((response: any) => response.json())
                        .then((data: any) => {
                            setSubmitting(false);
                            setShortLink(data.short);
                        });
                }}>
                {({ isSubmitting }) => (
                    <Form className="form-container">
                        <Field className="input1" type="text" name="longUrl" />
                        <Field className="input2" type="text" name="shortUrl" />
                        <button className="submitButton" type="submit" disabled={isSubmitting}>
                            Shorten!
                        </button>
                    </Form>
                )}
            </Formik>
            <p className="border-bottom">
                <span className="border-bottom-text">
                    Short Link: {shortLink && `https://bfac.link/${shortLink}`}
                </span>
            </p>

        </>
    );
};

export default ShortForm;