import React from "react";
import { Formik, Form, Field } from 'formik';


const CloakForm = () => {
    const [cloak, setCloak] = React.useState('');
    return (
        <>
            <Formik
                initialValues={{ link: '', cloak: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    const requestOptions: RequestInit = {
                        method: 'POST',
                        credentials: "include",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(values)
                    };
                    fetch('https://app.bfac.link/api/v1/links/cloaks', requestOptions)
                        .then((response: any) => {
                            setSubmitting(false);
                            // @ts-ignore
                            setCloak(values.cloak);
                        });
                }}>
                {({ isSubmitting }) => (
                    <Form className="form-container">
                        <Field className="input1" type="text" name="link" />
                        <Field className="input2" type="text" name="cloak" />
                        <button className="submitButton" type="submit" disabled={isSubmitting}>
                            Cloak!
                        </button>
                    </Form>
                )}
            </Formik>
            <p className="border-bottom">
                <span className="border-bottom-text">
                    Cloak Link: {cloak && `https://from.bytefaction.com/to/${cloak}`}
                </span>
            </p>
        </>
    );
};

export default CloakForm;